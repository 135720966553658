import { validateModel } from '@sky-it-livedata-libraries/livedata-commons';
import React, { Suspense } from 'react';
import { IMatchChangeHpTemplate } from '@sky-it-livedata/livedata-sports-design-system';

import { IComponentModel } from '../../typings/common-types';
import HtmlWrapper from '../html-wrapper/html-wrapper';

const MatchChangeHpWrapper = React.lazy(() => import('./match-change-hp'));

export interface IDynamicMatchChangeHpWrapper extends IComponentModel<IMatchChangeHpTemplate> {
  live?: boolean;
  model: any;
}

const DynamicMatchChangeHp = ({
  /* istanbul ignore next: default value */
  children = null,
  /* istanbul ignore next: default value */
  live = false,
  /* istanbul ignore next: default value */
  model = {},
  /* istanbul ignore next: default value */
  query = {},
}: IDynamicMatchChangeHpWrapper) => (
  <Suspense fallback={<HtmlWrapper html={children} />}>
    <MatchChangeHpWrapper
      live={live}
      initialModel={validateModel(model) as IMatchChangeHpTemplate}
      query={validateModel(query)}
    />
  </Suspense>
);

DynamicMatchChangeHp.defaultProps = {
  live: false,
};

export default DynamicMatchChangeHp;
