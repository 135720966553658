import { validateModel } from '@sky-it-livedata-libraries/livedata-commons';
import { ICardMotorsportsResults } from '@sky-it-livedata/livedata-sports-design-system';
import React, { Suspense } from 'react';

import { IComponentModel } from '../../typings/common-types';
import HtmlWrapper from '../html-wrapper/html-wrapper';

const DataCardMotorsportResultsWrapper = React.lazy(() => import('./data-card-motorsport-results'));

export interface IDynamicDataCardMotorsportResults extends IComponentModel<ICardMotorsportsResults> {
  // eslint-disable-next-line react/require-default-props
  live?: boolean;
}

const DynamicDataCardMotorsportResults = ({
  /* istanbul ignore next: default value */ model = {} as ICardMotorsportsResults,
  /* istanbul ignore next: default value */ query = {},
  /* istanbul ignore next: default value */ children = null,
  /* istanbul ignore next: default value */ live = false,
}: IDynamicDataCardMotorsportResults) => (
  <Suspense fallback={<HtmlWrapper html={children} />}>
    <DataCardMotorsportResultsWrapper
      live={live}
      initialModel={validateModel(model) as ICardMotorsportsResults}
      query={validateModel(query)}
    />
  </Suspense>
);

export default DynamicDataCardMotorsportResults;
