import { validateModel } from '@sky-it-livedata-libraries/livedata-commons';
import { ICardMotorsportsTeamsRanking } from '@sky-it-livedata/livedata-sports-design-system';
import React, { Suspense } from 'react';

import { IComponentModel } from '../../typings/common-types';
import HtmlWrapper from '../html-wrapper/html-wrapper';

const CardMotorsportsTeamsRankingWrapper = React.lazy(() => import('./card-motorsports-teams-ranking'));

export interface IDynamicCardMotorsportsTeamsRanking extends IComponentModel<ICardMotorsportsTeamsRanking> {
  // eslint-disable-next-line react/require-default-props
  live?: boolean;
}

const DynamicCardMotorsportsTeamsRanking = ({
  /* istanbul ignore next: default value */ model = {} as ICardMotorsportsTeamsRanking,
  /* istanbul ignore next: default value */ query = {},
  /* istanbul ignore next: default value */ children = null,
  /* istanbul ignore next: default value */ live = false,
}: IDynamicCardMotorsportsTeamsRanking) => (
  <Suspense fallback={<HtmlWrapper html={children} />}>
    <CardMotorsportsTeamsRankingWrapper
      live={live}
      initialModel={validateModel(model) as ICardMotorsportsTeamsRanking}
      query={validateModel(query)}
    />
  </Suspense>
);

export default DynamicCardMotorsportsTeamsRanking;
