import React, { ReactNode } from 'react';

interface IHtmlWrapper {
  html: ReactNode;
}

interface IInnerHTML {
  __html: string;
}

const buildHtmlCode = (children: ReactNode): IInnerHTML => ({ __html: String(children) });

/**
 * The component use dangerouslySetInnerHTML - take care of use it only for Suspence white transition layer
 * and do not use innerHTML for other component due the XSS security issue
 */
const HtmlWrapper = ({ html }: IHtmlWrapper) => <div dangerouslySetInnerHTML={buildHtmlCode(html)} />;

export default HtmlWrapper;
