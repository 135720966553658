import { validateModel } from '@sky-it-livedata-libraries/livedata-commons';
import { ICardFootballRanking } from '@sky-it-livedata/livedata-sports-design-system';
import React, { Suspense } from 'react';

import { IComponentModel } from '../../typings/common-types';
import HtmlWrapper from '../html-wrapper/html-wrapper';

const DataCardRankingWrapper = React.lazy(() => import('./data-card-football-ranking'));

export interface IDynamicDataCardRanking extends IComponentModel<ICardFootballRanking> {
  // eslint-disable-next-line react/require-default-props
  live?: boolean;
}

const DynamicDataCardFootballRanking = ({
  /* istanbul ignore next: default value */ model = {} as ICardFootballRanking,
  /* istanbul ignore next: default value */ query = {},
  /* istanbul ignore next: default value */ children = null,
  /* istanbul ignore next: default value */ live = false,
}: IDynamicDataCardRanking) => (
  <Suspense fallback={<HtmlWrapper html={children} />}>
    <DataCardRankingWrapper
      live={live}
      initialModel={validateModel(model) as ICardFootballRanking}
      query={validateModel(query)}
    />
  </Suspense>
);

export default DynamicDataCardFootballRanking;
