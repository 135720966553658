import { validateModel } from '@sky-it-livedata-libraries/livedata-commons';
import { ICardMotorsportsCalendar } from '@sky-it-livedata/livedata-sports-design-system';
import React, { Suspense } from 'react';

import { IComponentModel } from '../../typings/common-types';
import HtmlWrapper from '../html-wrapper/html-wrapper';

const DataCardMotorsportCalendarWrapper = React.lazy(() => import('./data-card-motorsport-calendar'));

export interface IDynamicDataCardMotorsportCalendar extends IComponentModel<ICardMotorsportsCalendar> {
  // eslint-disable-next-line react/require-default-props
  live?: boolean;
}

const DynamicDataCardMotorsportCalendar = ({
  /* istanbul ignore next: default value */ model = {} as ICardMotorsportsCalendar,
  /* istanbul ignore next: default value */ query = {},
  /* istanbul ignore next: default value */ children = null,
  /* istanbul ignore next: default value */ live = false,
}: IDynamicDataCardMotorsportCalendar) => (
  <Suspense fallback={<HtmlWrapper html={children} />}>
    <DataCardMotorsportCalendarWrapper
      live={live}
      initialModel={validateModel(model) as ICardMotorsportsCalendar}
      query={validateModel(query)}
    />
  </Suspense>
);

export default DynamicDataCardMotorsportCalendar;
