import { validateModel } from '@sky-it-livedata-libraries/livedata-commons';
import { IMatchCardDataBlock, IMatchDataBlockLayout } from '@sky-it-livedata/livedata-sports-design-system';
import React, { Suspense } from 'react';

import { IComponentModel } from '../../typings/common-types';
import HtmlWrapper from '../html-wrapper/html-wrapper';

const MatchCardDetailWrapper = React.lazy(() => import('./match-card'));

export interface IDynamicMatchCardDetailWrapper extends IComponentModel<IMatchCardDataBlock> {
  // eslint-disable-next-line react/require-default-props
  live?: boolean;
  layout: Partial<IMatchDataBlockLayout>;
}

const DynamicMatchCardDetail = ({
  /* istanbul ignore next: default value */ model = {} as IMatchCardDataBlock,
  /* istanbul ignore next: default value */ query = {},
  /* istanbul ignore next: default value */ children = null,
  /* istanbul ignore next: default value */ live = false,
  layout,
}: IDynamicMatchCardDetailWrapper) => (
  <Suspense fallback={<HtmlWrapper html={children} />}>
    <MatchCardDetailWrapper
      live={live}
      initialModel={validateModel(model) as IMatchCardDataBlock}
      query={validateModel(query)}
      layout={layout}
    />
  </Suspense>
);

export default DynamicMatchCardDetail;
