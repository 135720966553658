import React, { Suspense } from 'react';
import { ICardFootballCalendar } from '@sky-it-livedata/livedata-sports-design-system';
import { validateModel } from '@sky-it-livedata-libraries/livedata-commons';

import { IComponentModel } from '../../typings/common-types';
import HtmlWrapper from '../html-wrapper/html-wrapper';

const DataCardFootballCalendarWrapper = React.lazy(() => import('./data-card-football-calendar'));

export interface IDynamicDataCardRanking extends IComponentModel<ICardFootballCalendar> {
  // eslint-disable-next-line react/require-default-props
  live?: boolean;
}

const DynamicDataCardFootballCalendar = ({
  /* istanbul ignore next: default value */ model = {} as ICardFootballCalendar,
  /* istanbul ignore next: default value */ query = {},
  /* istanbul ignore next: default value */ children = null,
  /* istanbul ignore next: default value */ live = false,
}: IDynamicDataCardRanking) => (
  <Suspense fallback={<HtmlWrapper html={children} />}>
    <DataCardFootballCalendarWrapper
      live={live}
      initialModel={validateModel(model) as ICardFootballCalendar}
      query={validateModel(query)}
    />
  </Suspense>
);

export default DynamicDataCardFootballCalendar;
